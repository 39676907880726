// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-massageencoaching-js": () => import("./../../../src/pages/massageencoaching.js" /* webpackChunkName: "component---src-pages-massageencoaching-js" */),
  "component---src-pages-overmijenzelfliefde-js": () => import("./../../../src/pages/overmijenzelfliefde.js" /* webpackChunkName: "component---src-pages-overmijenzelfliefde-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-zelfliefde-js": () => import("./../../../src/pages/zelfliefde.js" /* webpackChunkName: "component---src-pages-zelfliefde-js" */)
}

